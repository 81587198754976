import { Component, OnInit } from '@angular/core';
import { CurrentTimeService } from 'src/app/current-time.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    currentTime: string;

  constructor(private currentTimeService: CurrentTimeService) { }

  ngOnInit() {
    this.getCurrentTime();
  }

  getCurrentTime(): void {
      this.currentTimeService.get().subscribe(time => this.currentTime = time);
      // this.currentTime = this.currentTimeService.get();
  }

}
