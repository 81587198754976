import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CurrentTimeService {

  private baseUrl = `https://vqp4wt8063.execute-api.us-east-1.amazonaws.com/default`;

  constructor(private http: HttpClient) { }
  // constructor() { }

  get(): Observable<string> {
      const url = `${this.baseUrl}/showCurrentTimeFunction`;
      return this.http.get(url, { responseType: 'text' });
      // return of('test time');
  }
}
